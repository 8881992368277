import $ from 'jquery'
import '@babel/polyfill'
import 'bootstrap'
import Cookies from 'js-cookie'
import moment from 'moment'

const csrf_safe = method => /^(GET|HEAD|OPTIONS|TRACE)$/.test(method)
const csrf_token = Cookies.get('csrftoken')

window.moment = moment

$.ajaxSetup({
  cache: true,
  beforeSend: function (xhr, settings) {
    if (!csrf_safe(settings.type) && !this.crossDomain) {
      xhr.setRequestHeader('X-CSRFToken', csrf_token)
    }
  }
})
// https://statuscode.ch/2017/03/CSP-unsafe-eval-and-jquery prevent evaluating js when loading content with .html()
// $.globalEval = () => {}

import render from './main/render'
render()

import marked from 'marked'
import '../libs/js/select2.full'
import '../libs/js/rating'

const renderer = new marked.Renderer()
const originalImageRenderer = renderer.image
renderer.image = function (href, title, text) {
  // Use the original image renderer and just replace the img tag with styling
  return originalImageRenderer.call(this, href, title, text)
    .replace('<img', '<img class="img-fluid"')
}

marked.setOptions({breaks: true, renderer: renderer})

import './main/nav'
import './main/search'
// Render selects when importing here
import './tools/select2'
import './main/select2_heavy'
import './main/ga'
import './main/intercom'
import './main/forms'
import {colour_contrast} from './tools/colour_contrast'

const exec_module = (p, page_data) => p.then(m => m.default(page_data)).catch(e => {
  console.warn(e)

  const cant_reload = msg => {
    console.error(msg)
    window.Sentry.captureException(e, {extra: {UncaughtPromise: true, msg: msg}})
    window.alert(
      'error loading scripts, try reloading the page. ' +
      'If this problem persists contact support@tutorcruncher.com.'
    )
    return false
  }
  if (!window.localStorageOn) {
    return cant_reload('local storage not available, not attempting page reload')
  }

  const storage_key = 'import_error_reload:' + window.location.href
  let storage_value = window.sessionStorage[storage_key]
  window.log(`storage_key: ${storage_key}, value: ${storage_value}`)
  if (storage_value) {
    return cant_reload('page already reloaded in this session due to import errors')
  }
  window.sessionStorage[storage_key] = 1
  setTimeout(() => window.location.reload(), 500)
})

$(document).on('nav', () => {
  // Used for select2s to make the input focus when on modal
  $.fn.modal.Constructor.prototype._enforceFocus = function () {}

  let selector = $('body')
  if ($('[id="tc-modal"]').length) {
    selector = $('[id="tc-modal"]:last')
  }

  const $sidebar = $('#sidebar')
  window.responsive.request_mobile && $sidebar.collapse('hide')

  if (selector.find('.date-range-picker').length) {
    exec_module(import(/* webpackChunkName: "tc_daterangepicker" */ './defer/tc_daterangepicker'))
  }

  if (selector.find('.date-time-picker').not('.loaded').length) {
    exec_module(import(/* webpackChunkName: "tc_datetimepicker" */ './defer/tc_datetimepicker'))
  }

  if (selector.find('.md-editor').length) {
    exec_module(import(/* webpackChunkName: "md_editor" */ './defer/md_editor'))
  }

  selector.find('.label-colour').each((i, e) => colour_contrast($(e)))
  const js_loaded = []
  selector.find('[data-js]').each(function () {
    const $this = $(this)
    const load_js = $(this).data('js')
    if (js_loaded.includes(load_js)) {
      window.log(`"${load_js}" already loaded`)
    } else {
      js_loaded.push(load_js)
      window.log(`loading js "${load_js}"`)
      let data = {}
      if ($this.attr('type') === 'application/json') {
        data = JSON.parse($this.text())
      }
      exec_module(import(/* webpackChunkName: "[request]" */ `./defer/${load_js}`), data)
    }
  })

  // don't use overlay for mailto/download/tel links
  selector.find('a[href^="mailto:"], a[download], a[href^="tel:"]').click(() => {
    window.show_overlay = false
  })

  // enable tooltips
  selector.find('[data-toggle="tooltip"]').tooltip({boundary: 'window'})
  selector.find('[data-toggle="tooltip-wide"]').tooltip({
    boundary: 'window',
    template: '<div class="tooltip tooltip-wide" role="tooltip"><div class="arrow"></div><div class="tooltip-inner"></div></div>'
  })

  selector.find(document).ready(() => {
    selector.find('.tooltip.show').hide()
  })

  selector.find('.render-md').each(function () {
    this.innerHTML = marked(this.innerHTML.trim())
  })

  selector.find('.role-set-label').click(function () {
    selector.find(this).addClass('disabled').attr('disabled', true)
  })

  selector.find('#load-intercom').click(e => {
    e.preventDefault()
    window.Intercom('showMessages')
  })

  selector.find('.popover.fade').remove()

  selector.find(document).click(e => {
    if (!$sidebar.has(e.target).length) {
      $sidebar.collapse('hide')
    }
  })

  selector.find('.btn[disabled]').click(e => e.preventDefault())
  selector.find('.disabled').click(e => e.preventDefault())

  selector.find('#sidebar>#menu_accordion>div>div>div>a').on('click', () => {
    selector.find('#sidebar').collapse('hide')
  })

  selector.find('[data-parent-classes]').each((i, el) => {
    let $el = $(el)
    $el.parent().addClass($el.data('parent-classes'))
  })

  $('.copy-to-clipboard').on('click', e => {
    let $input = $(`<input>`)
    $('body').append($input)
    $input.val($(e.target).data('copy-text')).select()
    document.execCommand('copy')
    $input.remove()

    let $tooltip = $('.tooltip')
    let tooltip_width = $tooltip.width()
    $tooltip.show()
    $('.tooltip-inner').css('width', tooltip_width).text('Copied!')
    $(e.target).mouseover()
  })
})

if (window.localStorageOn && window.localStorage['_test_errors']) {
  window.log('throwing test error...')
  throw Error('test error thrown based on localStorage._test_errors')
}

import './main/browser_update'
