import $ from 'jquery'
import main_menu_template from '../templates/main-menu.njk'
import branch_menu_template from '../templates/branch_menu.njk'
import account_menu_template from '../templates/account-menu.njk'
import {trigger_nav, ajax_error} from '../tools/nav'
import get_data from '../tools/data'

const $menu_accordion = $('#menu_accordion')
const page_data = get_data('render-data')
const active = item => item.active || item.url === page_data.active_url

function subactive (item) {
  if (!item.never_open) {
    item.submenu.forEach((item_) => {
      if (active(item_)) {
        return true
      }
    })
  }
  return false
}

function render_menus (menu_data) {
  const main_menu_data = menu_data['main_menu']
  const branch_menu_data = menu_data['branch_menu']
  const current_branch = menu_data['current_branch']

  const _mob = $(window).width() < 768
  const main_menu = main_menu_template.render({
    data: main_menu_data,
    active,
    subactive,
    visible: items => items.filter(item => !(
      (_mob && item.vis === 'desktop') || (!_mob && item.vis === 'mobile'))
    ),
  })
  $menu_accordion.html(main_menu)

  const $card = $menu_accordion.find('.card')
  $card.on('hide.bs.collapse', function (event) {
    if ($(event.target).parent()[0] === this) {
      $(this).removeClass('inside')
    }
  })

  $card.on('show.bs.collapse', function (event) {
    if ($(event.target).parent()[0] === this) {
      $(this).addClass('inside')
    }
  })

  const account_menu = account_menu_template.render({
    data: main_menu_data,
    full_name: page_data.user_full_name,
    display: items => items.filter(item => item.account_menu)
  })

  $('#account-menu').html(account_menu)
  $('#branch-menu').html(branch_menu_template.render({data: branch_menu_data, current_branch: current_branch}))

  window._templates_loaded = true
  trigger_nav(`templates loaded "${window.location.pathname}"`)
}

export default function () {
  if (document.querySelector('#full-overlay') === null) {
    console.warn('partial page load, reloading...')
    window.location.reload()
  }

  if (window.localStorageOn) {
    const menu_data = window.sessionStorage[page_data.menu_url]
    if (menu_data) {
      render_menus(window.JSON.parse(menu_data))
      return
    }
  }
  $.getJSON(page_data.menu_url, menu_data => {
    render_menus(menu_data)
    if (window.localStorageOn) {
      window.sessionStorage[page_data.menu_url] = window.JSON.stringify(menu_data)
    }
  }).fail((xhr, ajaxOptions, thrownError) => ajax_error('GET', page_data.menu_url, xhr, ajaxOptions, thrownError))
}
