import $ from 'jquery'
import get_data from '../tools/data'

const ic_data = get_data('intercom-data')

// skip activating intercom in tests = intercom returning 503 is a king sized ball ache.
if (!window.sys.TESTING && ic_data.load_intercom) {
  // rewritten from intercom snippet
  const intercom = function () {
    intercom.c(arguments)
  }
  intercom.q = []
  intercom.c = function (args) {
    intercom.q.push(args)
  }
  window.Intercom = intercom
  window.Intercom('boot', ic_data.intercom_data)

  $(document).ready(function () {
    const script = document.createElement('script')
    script.async = true
    script.src = 'https://widget.intercom.io/widget/' + window.sys.IC_ANALYTICS_TRACKING_ID
    $(document.body).append(script)
  })
}

export default function () {}
