import bootbox from 'bootbox'
import marked from 'marked'
import $ from 'jquery'
import {
  go_to,
  create_and_launch_modal,
  init_confirm_follow,
  trigger_nav,
  replacestate,
  setup_modal_links,
  menu_toggled_check,
} from '../tools/nav'

// create_and_launch_modal is used by invoice-staging-list
window.create_and_launch_modal = create_and_launch_modal

// just add step to the state, null means this will use the current url
replacestate(null)
// update page on back button
window.onpopstate = event => {
  const new_path = window.location.pathname + window.location.search + window.location.hash
  if (event.state && event.state.step) {
    go_to(new_path, event.state)
  } else {
    // this apparently happens when old safari first loads a page
    console.warn('popstate event without tc state', new_path, event.state)
  }
}

$(document).on('nav', () => {
  // assume pushstate nav only works if the variable content div is present
  if ($('#variable-content').length) {
    $('a[href^="/"]')
      .not('.nav-ignore')
      .not('[target="_blank"],[data-confirm],[data-method="POST"],[data-toggle="crud-modal"]')
      .not('[download]')
      .click(function (e) {
        if (e.shiftKey || e.ctrlKey || e.metaKey) {
          return true
        } else {
          return go_to($(this).attr('href'))
        }
      })
  }

  init_confirm_follow()

  $('.show-box').click(function (e) {
    e.preventDefault()
    bootbox.dialog({
      message: marked($(this).attr('msg-body')),
      title: $(this).attr('msg-title')
    })
  })

  $('a.submit-modal').on('click', function (e) {
    e.preventDefault()
    if (!$(this).hasClass('disabled')) {
      $('.modal-body form').submit()
      $(this).addClass('disabled')
    }
  })

  // Find all forms and disable submit buttons after submit. In general this duplicates overlay functionality
  $('form').submit(() => {
    $('input[type=submit], button[type=submit]').attr('disabled', 'disabled')
  })
  setup_modal_links()
  menu_toggled_check()

  $('.dropdown > .dropdown-menu button.dropdown-toggle').click(function () {
    if (!$(this).next().hasClass('show')) {
      $(this).parents('.dropdown-menu').first().find('.show').removeClass('show')
    }
    const $subMenu = $(this).next('.dropdown-menu')
    $subMenu.toggleClass('show')

    $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', e => {
      $('.dropdown-submenu .show').removeClass('show')
    })
    return false
  })
})

$(document).ready(() => {
  window._document_ready = true
  trigger_nav(`document ready "${window.location.pathname}"`)
})

export default function () {
}
