import $ from 'jquery'
import '../../libs/js/rating'

export function render_star_form_fields () {
  let $inputStars = $(':input.stars')
  $('.c-rating__item').remove()
  $inputStars.each(function (i, el) {
    let $el = $(el)
    let $parent = $el.parent()
    window.rating($parent[0], $el.val(), $el.data('max-stars'), (r) => $el.val(r))
    $el.parent().addClass('c-rating')
    $el.removeAttr('required')
    $el.hide()
  })
}
