import $ from 'jquery'
import {render_star_form_fields} from './stars'

const periods = ['week', 'day', 'hour', 'min']
const periods_ms = [
  604800000,      // week in ms
  86400000,       // day in ms
  3600000,        // hour in ms
  60000           // min in ms
]

// our very own humanize function as a replacement to moment.humanize
// allowing us to apply our own formatting.
$.extend({
  humanizedMilliseconds: function (milliseconds) {
    if (milliseconds < 1) {
      return ''
    }
    let periodsResult = ''
    let tmp_period
    let rollingDuration = milliseconds
    for (let i = 0; i < periods.length; i++) {
      tmp_period = Math.floor(rollingDuration / periods_ms[i])
      rollingDuration = rollingDuration % periods_ms[i]
      let postfix = periods[i]
      if (tmp_period === 0) {
        continue
      } else if (tmp_period > 1) {
        postfix = postfix + 's'
      }
      periodsResult += tmp_period + ' ' + postfix + ' '
    }
    return periodsResult
  }
})

$(document).on('nav', () => {
  // Checks if form fields are valid
  // Highlights invalid fields & opens collapsible panels containing them
  // Scrolls to first invalid field
  $('#form-submit').click(function (e) {
    const $req_fields = $(this).parents('form:first').find(':required')
    let scroll_field = false
    let panel_opened = false
    $req_fields.each(function () {
      const req_field = $(this)
      if ((req_field.is(':checkbox') && !req_field.is(':checked')) ||
        req_field.val() === '' || req_field.val() === '---------') {
        req_field.removeClass('is-valid').addClass('is-invalid')
        let req_field_panel = req_field.parents('.card-collapse')
        if (!panel_opened && req_field_panel.not('show')) {
          panel_opened = true
          req_field_panel.collapse('show')
        }
        if (!scroll_field) {
          scroll_field = req_field
        }
      } else {
        req_field.removeClass('is-invalid').addClass('is-valid')
      }
    })
    if (scroll_field) {
      $('html,body').animate({scrollTop: scroll_field.offset().top - 100})
    }
  })
  window.form_submitted = false
  window.form_is_dirty = false

  $('input[disabled]').siblings('.input-group-append').off('click')

  if ($(':input.stars').length) {
    render_star_form_fields()
  }

  $('input[type="submit"], button[type="submit"]').click(() => { window.form_submitted = true })

  $('.main').find('form[method="post"]')
    .find('input, textarea')
    .not('#id_calendar_colour')
    .change(() => { window.form_is_dirty = true })

  $('.on-change-submit').each(function () {
    const $form = $(this)
    const change_fields = $form.data('change-fields').map(f => '#' + f)
    change_fields.forEach((f) => {
      $form.find(f).change(() => $form.submit())
    })
  })
  $('.tab-filters').css('visibility', 'visible')
})

const user_exit_warning = window.gettext('Your data will be lost.')
// Prevent a user from exiting a page and losing their data unwillingly.
$(window).on('beforeunload', (e) => {
  if (!window.form_submitted && window.form_is_dirty) {
    window.show_overlay = false
    const _event = e || window.event
    _event.returnValue = user_exit_warning
    return user_exit_warning
  }
})

export default function () {}
