import $ from 'jquery'
import bootbox from 'bootbox'

export function ajax_error (method, url, xhr, ajaxOptions, thrownError) {
  window.Sentry.captureMessage(
    `Ajax Error: ${xhr.status} ${method} ${url}`,
    {
      stacktrace: true,
      level: 'warning',
      extra: {method, url, xhr, ajaxOptions, thrownError}
    }
  )
  if (xhr.status !== 0) {
    window.alert(`Error ${xhr.status}: ${xhr.statusText}. 
    If this problem persists, please contact support@tutorcruncher.com.`)
  }
}

const unix_time = () => (new Date()).getTime() / 1000

let pushstate_step = 1
export function pushstate (uri, response_text) {
  window.history.pushState({step: pushstate_step, response_text, unix_time: unix_time()}, '', uri)
}

export function replacestate (uri) {
  window.history.replaceState({step: pushstate_step}, '', uri)
}

export function trigger_nav (reason) {
  // checking the two above values means only the latter of render template and document ready will fire triggers
  if (window._document_ready && window._templates_loaded) {
    window.log(`triggering nav event: ${reason}`)
    $('a').off('click')
    $(document).trigger('nav', reason)
  }
}

function nav_response_error (reason, uri, response) {
  const msg = `pushstate nav error going to "${uri}" from "${window.location.pathname}", reason: ${reason}`
  window.Sentry.captureMessage(msg, {
    stacktrace: true,
    level: 'warning',
    extra: {uri, response, location: window.location}
  })
  window.location.href = uri
}

let old_uri
window.custom_listeners = {}

export function add_listener (name, listener) {
  const id = Math.floor(Math.random() * 1e6)
  window.custom_listeners[id] = {func: listener, name}
  return () => {
    delete window.custom_listeners[id]
  }
}

function execute_listeners (listener_name) {
  Object.values(window.custom_listeners)
    .filter(l => l.name === listener_name)
    .forEach(l => l.func())
}

export function go_to (uri, state) {
  const $menu_accordion = $('#menu_accordion')
  const $variable_content = $('#variable-content')
  const $variable_overlay = $('#variable-overlay')

  function set_content (response_text, new_uri) {
    window.scrollTo(0, 0)
    $variable_overlay.addClass('fade-out')
    setTimeout(() => {
      $variable_overlay.hide()
      $variable_overlay.removeClass('fade-out')
    }, 200)

    !state && pushstate(new_uri, response_text)
    $variable_content.html(response_text)

    // GA4 start - track pageview for dynamic faked page reload
    gtag('set', 'page_path', new_uri)
    gtag('event', 'page_view')
    // GA4 end

    $menu_accordion.find('a.active').removeClass('active')
    const old_open = $menu_accordion.find('.collapse.show').data('item')
    const $data_div = $variable_content.find('>div')
    const $active_a = $menu_accordion.find(`a[href="${$data_div.data('active-url')}"]`)
    $active_a.addClass('active')
    const new_open = $active_a.closest('.collapse').data('item')

    if (old_open !== new_open) {
      if (new_open) {
        $active_a.closest('.card').find('a.menu-item.toggle').trigger('click')
      } else {
        $menu_accordion.find('.card')
          .filter((i, el) => $(el).has('.collapse.show').length)
          .find('a.menu-item.toggle')
          .trigger('click')
      }
    }

    document.title = $data_div.data('title')
    $('.search [name="search"]').typeahead('val', '')
  }

  if (uri.indexOf('/') !== 0) {
    throw Error(`go_to with a uri which doesn't start with a /: "${uri}"`)
  }

  if (!window.form_submitted && window.form_is_dirty) {
    if (!window.confirm(window.gettext('If you continue your data will be lost.'))) {
      if (state && old_uri) {
        // push the previous url back into state so the url "stays the same"
        pushstate(old_uri)
      }
      return false
    }
  }

  if (!state && uri === window.location.pathname) {
    // same page, reload the page in case some views assume this will occur
    window.log(`go to current uri "${uri}", reloading`)
    window.location.href = uri
    return false
  }

  execute_listeners('before_go_to')

  $variable_overlay.show()

  old_uri = uri
  let bf
  if (state) {
    bf = state.step < pushstate_step ? 'back to' : 'forward to'
    pushstate_step = state.step
  } else {
    bf = 'to'
    pushstate_step += 1
  }
  window.log(`going ${bf} "${uri}" step ${pushstate_step}`)

  function _go () {
    if (state && state.response_text && (unix_time() - state.unix_time) < 30) {
      set_content(state.response_text, uri)
      trigger_nav(`reset content at "${uri}"`)
      return
    }

    const xhr = new window.XMLHttpRequest()
    const on_error = msg => {
      if (xhr.status !== 403 && xhr.status !== 404) {
        if (xhr.status === 0) {
          /**
           * If the XMLHttpRequest status is 0, capture the error message with a lower 'log' level
           * and include the XMLHttpRequest object and URI in the extra data.
           */
          window.Sentry.captureMessage(msg, {
            stacktrace: true,
            level: 'log',
            extra: { xhr, uri }
          })
        } else {
          /**
           * If the XMLHttpRequest status is not 0, capture the error message with a 'warning' level
           * and include the XMLHttpRequest object and URI in the extra data.
           */
          window.Sentry.captureMessage(msg, {
            stacktrace: true,
            level: 'warning',
            extra: { xhr, uri }
          })
        }
      } else {
        window.log(msg)
      }
      window.location.href = uri
    }
    xhr.open('GET', uri)
    xhr.setRequestHeader('X-Requested-With', 'pushstate')
    xhr.onload = () => {
      if (xhr.status === 200) {
        const response_text = xhr.responseText
        if (typeof response_text !== 'string') {
          return nav_response_error('non string response', uri, response_text)
        }

        const doc_type = response_text.indexOf('!DOCTYPE')
        if (doc_type > 0 && doc_type < 50) {
          // we've "gone to" a page which doesn't support X-Requested-With: pushstate
          return nav_response_error('full http response', uri, response_text)
        }
        let next_url = uri
        if (xhr.responseURL) {
          next_url = xhr.responseURL.replace(/https?:\/\/.*?\//, '/')
        }
        set_content(response_text, next_url)
        trigger_nav(`gone to "${next_url}"`)
      } else {
        on_error(`wrong response code ${xhr.status}, Response: ${xhr.responseText.substr(0, 500)}`)
      }
    }
    xhr.onerror = () => on_error(`Error requesting data ${xhr.statusText}: ${xhr.status}`)
    xhr.send()
  }

  const $modal = $('.modal')
  if ($modal.is(':visible')) {
    $modal.on('hidden.bs.modal', _go)
    $modal.modal('hide')
  } else {
    _go()
  }

  window.custom_listeners = {}
  return false
}

export function submit_modal_form (e, form, modal) {
  e.preventDefault()
  e.stopPropagation()
  const $form = $(form)
  const data = new window.FormData($form[0])
  const url = $form.attr('action')
  const method = $form.attr('method')
  window.form_submitted = true
  $.ajax({
    type: method,
    url: url,
    data: data,
    processData: false,
    contentType: false,
    cache: false,
    success: function (response_text) {
      if (response_text.indexOf('<!--IS_MODAL-->') >= 0) {
        $(modal).find('#ajax-content').html(response_text)
        const $form = $(modal).find('form')
        $form.attr('action', url)
        modal_form_submit($form, modal)
        trigger_nav('modal form returned 200')
      } else {
        $(modal).modal('toggle')
        if (response_text.indexOf('/') === 0 && response_text.length < 200) {
          // this is a link, we should follow it
          // TODO we should use go_to here
          document.location.href = response_text

          if (response_text.indexOf('#') >= 0) {
            document.location.reload()
          }
        } else {
          document.location.reload()
        }
      }
    },
    error: (...args) => ajax_error(method, url, ...args)
  })
}

function modal_form_submit (form, modal) {
  $(form).not('.custom-submit').submit(function (e) {
    submit_modal_form(e, this, modal)
  })
}

function filter_modal_form_submit ($form) {
  $form.submit(function (e) {
    e.preventDefault()
    e.stopPropagation()
    const filled_fields = $form.find(':input').filter((i, el) => $(el).val() !== '')
    const qs = filled_fields.serialize()
    const url = $form.data('action') + '?' + qs
    go_to(url)
  })
}

const modal_str = `<div class="modal fade" id="tc-modal" tabindex="-1">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title"></h4>
        <button type="button" data-dismiss="modal" class="close"><span>&times;</span></button>
      </div>
      <div id="ajax-content">
        <div class="modal-body">
          <div class="spinner-container fade-in-medium">
            <div class="spinner">
              <div></div><div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>`

export async function create_and_launch_modal (url, title, modal_lg, $parent_modal) {
  const modal_count = $('.modal-dialog').length
  if (modal_count > 2) {
    throw Error(`create_and_launch_modal called when ${modal_count} modals are already displayed`)
  }
  if (url.indexOf('#') === 0) {
    return
  }
  // define the modal structure
  const $modal = $(modal_str)
  if (modal_lg) {
    $modal.find('.modal-dialog').addClass('modal-lg')
  }

  if ($parent_modal) {
    $parent_modal.hide()
  }
  $modal.modal({keyboard: false, backdrop: 'static', show: true})
    .on('hidden.bs.modal', function (e) {
      if (e.target === this) {
        $modal.remove()
      }
    }).appendTo('body')
  let data
  try {
    data = await $.get(url).promise()
  } catch (e) {
    ajax_error('GET', url, e)
    $modal.modal('hide')
    if ($parent_modal) {
      $parent_modal.show()
    }
  }

  if (data.indexOf('/') === 0 && data.length < 200) {
    // FIXME this doesn't make sense
    window.log('redirecting as already applied for job')
    document.location.href = data
    return
  }
  const $model_ajax_content = $modal.find('#ajax-content')
  $model_ajax_content.html(data)
  $modal.find('.modal-title').html($modal.find('.modal-body').data('title') || title || '&nbsp;')
  const $form = $model_ajax_content.find('form')

  $modal.find('form[method="post"]')
    .find('textarea')
    .change(function () {
      window.form_is_dirty = true
    })
  if (!$form.attr('action')) {
    $form.attr('action', url)
  }
  if ($form.data('ajax-submit') !== false) {
    modal_form_submit($form, '#tc-modal')
  } else if ($form.data('submit-type') === 'filter') {
    filter_modal_form_submit($form, '#tc-modal')
  }

  const user_exit_warning = window.gettext('Your data will be lost.')
  trigger_nav('modal launched')
  $('[data-dismiss="modal"]').on('click', function (e) {
    e.preventDefault()
    if (window.form_is_dirty) {
      const cancel = window.confirm(user_exit_warning)
      window.form_is_dirty = !(cancel)
      return cancel
    } else {
      if ($parent_modal) {
        $parent_modal.show()
      }
    }
  })
}

export function post_method_handler ($el, parse_list = false) {
  const link = $el.attr('href')
  if (link === '#') {
    return
  }
  const form = $('#post-form')
  form.attr('action', link)
  Object.entries($el.data()).forEach(_data => {
    const key = _data[0]
    const value = _data[1]
    if (key !== 'method') {
      if (typeof value === 'object' && parse_list) {
        value.forEach(v => {
          $('<input>').attr({type: 'hidden', name: key, value: v}).appendTo(form)
        })
      } else {
        $('<input>').attr({type: 'hidden', name: key, value: value}).appendTo(form)
      }
    }
  })
  form.submit()
}

function confirm_handler (e) {
  const $a = $(this)
  const target = $a.attr('target')
  const link = $a.attr('href')
  const method = $a.data('method') || 'POST'
  e.preventDefault()
  // Want to hide already existing modals if needs be
  const parent_modal = $a.parents('#tc-modal')
  if (parent_modal.length) {
    parent_modal.hide()
  }
  bootbox.confirm({
    message: $a.data('confirm'),
    title: $a.data('confirm-title') || null,
    callback: result => {
      if (result) {
        if (method.toLowerCase() === 'post') {
          let form = $('#post-form')
          form.attr('action', link)
          $.each($a.data(), function (k, v) {
            if (k === 'method') {
              return
            }
            $('<input>').attr({
              type: 'hidden',
              name: k,
              value: v
            }).appendTo(form)
          })
          if (target) {
            window.open(link, target)
          } else {
            form.submit()
          }
        } else {
          document.location.href = link
        }
      } else if (parent_modal.length) {
        // We want to show the modal again if we cancel the bootbox
        parent_modal.show()
      }
    }
  })
}

export function init_confirm_follow ($el) {
  $el = $el || $(document)
  $el.find('[data-confirm]').not('[disabled]')
    .unbind('click', confirm_handler)
    .click(confirm_handler)

  $el.find('[data-method="POST"]').not('[data-confirm]').not('.no-submit')
    .click(function (e) {
      e.preventDefault()
      if (e.button !== 2) {
        // Preventing middle click for Safari
        $(this).addClass('disabled').attr('disabled', true)
        post_method_handler($(this))
      }
    })
    .on('auxclick', function (e) {
      // For FF and Chrome auxclick is used for middle and right click
      if (e.button === 1) {
        e.preventDefault()
      }
    })
}

export function setup_modal_links ($el) {
  $el = $el || $(document)
  $el.find('[data-toggle="crud-modal"]').on('click', function (e) {
    e.preventDefault()
    const $this = $(this)
    if ($this.attr('disabled') !== undefined) {
      return
    }
    const url = $this.attr('href')
    // We get the modal title from link title attribute or from data-title one
    const title = $this.attr('title') || $this.data('title')
    const modal_lg = $this.data('modal-lg')
    const parent_modal = $this.parents('#tc-modal')
    create_and_launch_modal(url, title, modal_lg, parent_modal)
  })
}

export function menu_toggled_check () {
  const $menu_accordion = $('#menu_accordion')
  if (!$menu_accordion.find('.menu-item.depth_1.active').length && !$menu_accordion.find('.card.inside').length) {
    $menu_accordion
      .find('.menu-item.depth_2.active')
      .parents('.card')
      .find('a.menu-item.toggle')
      .trigger('click')
  }
}
