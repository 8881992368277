import 'corejs-typeahead/dist/typeahead.jquery'
import Bloodhound from 'corejs-typeahead/dist/bloodhound'
import $ from 'jquery'
import {go_to} from '../tools/nav'

const empty = `<div class="no-results">No results found</div>`

const search_suggestions = new Bloodhound({
  datumTokenizer: Bloodhound.tokenizers.whitespace,
  queryTokenizer: Bloodhound.tokenizers.whitespace,
  remote: {
    url: '/search-live.json?search={query}',
    wildcard: '{query}',
    rateLimitWait: 400
  }
})

const $search_spinner = $('#search-spinner')

$('.search [name="search"]').typeahead({
  minLength: 2
}, {
  source: search_suggestions,
  name: 'search',
  display: 'v',
  limit: 20,
  templates: {
    empty: empty,
    suggestion: function (result) {
      return `<div>${result.v} <span class="badge badge-pill float-right">${result.m}</span></div>`
    }
  }
}).on('typeahead:select', (ev, result) => go_to(result.u))
  .on('typeahead:asyncrequest', () => $search_spinner.show())
  .on('typeahead:asynccancel typeahead:asyncreceive', () => $search_spinner.hide())

export default function () {}
