import $ from 'jquery'

export function render_select_heavies () {
  $('.select2-heavy').not('.select2-offscreen').not('[id*=__prefix__]').each(function () {
    const $this = $(this)
    const multiple = $this.attr('multiple') !== undefined

    let url = $this.data('url')
    if ($this.data('extra-args')) {
      url += '?' + $this.data('extra-args')
    }
    /* If empty, remove contents, so data keeps intact, select2 reads it */
    if ($this.val() === ['[]']) {
      $this.val('')
    }
    $this.select2({
      ajax: {
        url: url,
        dataType: 'json',
        delay: 400,
        data: function (params) {
          return {
            name: params.term,
          }
        },
        processResults: function (data) {
          return {
            results: $.map(data, (item) => {
              return {
                text: item.text,
                id: item.id,
              }
            })
          }
        },
        cache: true
      },
      minimumInputLength: 3,
      multiple: multiple,
      allowClear: $this.attr('allow-clear') !== undefined,
      placeholder: $this.attr('placeholder') || '----------',
      theme: 'bootstrap4',
      width: '100%',
    })

    const initial_data = $this.data('initial')
    if ($.type(initial_data) === 'array') {
      const selected = []
      $.each(initial_data, (i, value) => {
        selected.push(value.id)
        $this.append($('<option>', {value: value.id, text: value.text}))
      })
      $this.val(selected).trigger('change')
    } else if ($.type(initial_data) === 'object') {
      $this.append($('<option>', {value: initial_data.id, text: initial_data.text}))
      $this.val(initial_data.id).trigger('change')
    }
  })
}

$(document).on('nav', () => {
  render_select_heavies()
})

export default function () {}
