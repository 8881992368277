function calc_yiq (c) {
  let r = 0
  let g = 0
  let b = 0
  if (/rgba/.test(c)) {
    c = c.replace('rgba(', '').replace(')', '').split(/,/)
    r = c[0]
    g = c[1]
    b = c[2]
  } else if (/rgb/.test(c)) {
    c = c.replace('rgb(', '').replace(')', '').split(/,/)
    r = c[0]
    g = c[1]
    b = c[2]
  } else if (/#/.test(c)) {
    c = c.replace('#', '')
    if (c.length === 3) {
      c = c[0] + c[0] + c[1] + c[1] + c[2] + c[2]
    }
    r = parseInt(c.substr(0, 2), 16)
    g = parseInt(c.substr(2, 2), 16)
    b = parseInt(c.substr(4, 2), 16)
  }
  const yiq = (r * 299 + g * 587 + b * 114) / 1000
  return yiq >= 128 ? 'light' : 'dark'
}

export function colour_contrast ($el, colour) {
  colour = colour || $el.css('background-color')
  $el.removeClass('dark light')
  $el.addClass(calc_yiq(colour))
}
