import $ from 'jquery'

// browser update version settings
window.$buoop = {
  vs: {
    i: 11,
    f: -4,
    o: -4,
    s: 8,
    c: -4
  },
  onshow () {
    $('.navbar').css('top', 50)
    $('#sidebar').css('top', 110)
    window.old_browser = true
  },
  onclose () {
    $('.navbar').css('top', 0)
    $('#sidebar').css('top', 60)
  },
  api: 4
}

$(document).ready(function () {
  $(document.body).append($('<script />', {src: 'https://browser-update.org/update.min.js'}))
})

export default function () {}
